import React, { Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"

import "../css/boardPage.css"
import SubjectPDFModal from "../components/subjectPDFModal"

export default class board extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSubject: "",
    }
  }

  
  propTypes = {
    data: PropTypes.string.isRequired,
  }

  render() {
    let data = this.props.pageContext

    return (
      <Layout>
        <SEO
          title={
            data.field_board_name +
            " - " +
            data.title +
            " - Tata Studi | Tata ClassEdge"
          }
          lang="en"
        />

        <div>
          <div className="pageBanner paymentPage bannerText">
            <h1 className="displayNone">Boards</h1>
            <h2 className="banner_text_new">
              {data.field_board_name} - {data.title}{" "}
            </h2>
          </div>
        </div>

        {/* <div className="photo_video_heading text-center">
          <p>
            <span className="heading-blue">{data.field_board_name}</span>
          </p>
        </div> */}

        <div className="container first-content font-lato mt-5">
          <span
            dangerouslySetInnerHTML={{
              __html: data.field_content_intro.processed,
            }}
          />
        </div>
        <br />
        <div className="photo_video_heading text-center">
          <p>
            {" "}
            {data.field_board_name} {data.title}&nbsp;
            <span className="heading-blue">Syllabus</span>
          </p>
        </div>

        <div className="container">
          <div className="row class-row">
            {data.field_subjects.map((item, i) => {
              return (
                <div
                  className="class-card col-sm-12 col-md-8 col-lg-5 my-4"
                  // data-toggle="modal"
                  // data-target="#pdfModal"
                  key={i}
                  // onClick={() => this.setState({ selectedSubject: item })}
                >
                  <div className="my-3 mx-1">
                    <span
                      className="font-weight-bold font-lato"
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {data.field_content_end ? (
          <div className="container later-content font-lato">
            {" "}
            <span
              dangerouslySetInnerHTML={{
                __html: data.field_content_end.processed,
              }}
            />
          </div>
        ) : (
          <> </>
        )}

        {/* MODAL FOR SUBJECT PDFS */}

        {/* <div
          id="pdfModal"
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="subject-pdf-modal"
          aria-hidden="false"
        >
          <SubjectPDFModal
            subject={this.state.selectedSubject}
            pdfList={data.relationships.field_subject_pdfs}
          />
        </div> */}
      </Layout>
    )
  }
}
